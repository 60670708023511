@import "styles/mixins";
@import "styles/variables";


.root {
    &:global.bg-white {
        .formio-component {
            .formio-form {
                a {
                    font-weight: 500;
                    text-decoration: underline;

                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                }

                input,
                textarea {
                    border: 1px solid $bg-light;
                }
            }
        }
        .formio-component {
            &.required {
                position: relative;
                margin-bottom: 2rem;
                &:before {
                    position: absolute;
                    bottom: -25px;
                }
            }
            input {
                border: 1px solid $bg-light;
                &::placeholder {
                    opacity: 0.5;
                }
            }
            &.formio-component-select {
                select {
                    padding: 0.875rem 1.2rem;
                    @include border-radius(25px);
                    &::placeholder {
                        opacity: 0.5;
                    }
                }
                input {
                    display: none;
                }
            }
        }
    }
}