@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .accordion-item {
            .wrapper {
                color: $base-white;
                background: $base-black;
            }
        }

        .accordion-button {
            background: transparent;
            color: $base-white;
            font-weight: 500;
            font-size: 1.2rem;
            box-shadow: none;
            padding-left: 0;
            padding-top: 0;

            &:hover,
            &:focus,
            &:active {
                color: $primary-color;
            }

            &:after,
            &:before {
                content: "";
                width: 15px;
                height: 2px;
                right: 0;
                position: absolute;
                background: $primary-color;
            }

            &:before {
                content: "";
                right: 0;
                position: absolute;
                @include transform(rotate(0deg));
            }

            &.collapsed {
                &:before {
                    content: "";

                    @include transform(rotate(90deg));
                }
            }
        }

        .accordion-body {
            padding: 0;
        }

        .content-preview {
            @include line-clamp(16px, 1.6, 4);

            &.active {
                height: auto;
                -webkit-line-clamp: inherit;
            }
        }

        @media (min-width: $breakpoint-md) {
            .accordion {
                .accordion-item {
                    width: calc(100%/2);
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            .accordion {
                .accordion-item {
                    width: calc(100%/4);
                }
            }
        }
    }
}