@import "styles/mixins";
@import "styles/variables";


.root {
    :global {

        .dialog-content {
            display: none;
        }

        .hotlines {
            a {

                &:hover,
                &:focus,
                &:active {
                    color: $font-color;
                    text-decoration: underline;
                }
            }
        }

        .locations-wrapper {
            a {

                &:hover,
                &:focus,
                &:active {
                    color: $font-color;
                    text-decoration: underline;

                    &.tertiaryBtn {
                        color: $base-white;
                        text-decoration: none;
                        background: $base-black;
                        opacity: 0.5;
                    }
                }

            }
        }

        // .post-address {
        //     font-weight: 500;

        //     p {
        //         font-weight: 500;
        //     }
        // }

        .contentWrapper {
            a {
                color: $font-color; 
                &:hover,
                &:focus,
                &:active {
                    color: $font-color;
                    text-decoration: underline;
                }
            }
        }

        .dropdown {
            .btn {
                text-align: left;
                background: $base-white;
                padding: 1rem 2rem;
                border: none;
                color: $base-black;
                @include border-radius(50px);

                &:after {
                    position: absolute;
                    right: 25px;
                    top: 20px;
                    display: block;
                    border: none;
                    background-image: url("/site-assets/svg/black-arrow.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    opacity: 0.5;
                    @include transform(rotate(90deg));
                }
            }
        }

    }
}